import React from 'react'
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import loadable from "@loadable/component";
import {Basic, Title} from "../components/SEO";
import VideoArticlePreview from "./videoArticlePreview";
import Pagination from "rc-pagination";
import 'rc-pagination/assets/index.css';
import CategoryTitleBordered from "../components/helpers/categoryTitleBordered";
import {adDisplayDelay, delayMultiplicationFactor, setStreamData} from "../utils/articleUtil";
import {videoCategoryPageAds} from "../utils/adUnits";
import {isMobile} from "react-device-detect";
import {metadata} from "../../config";
import { addTimeout } from '../utils/timeManager';

const VideoPopularPosts = loadable(() => import("../components/popularPosts/videoPopular"));

class VideoCategoryPage extends React.Component {

    constructor(props) {
        super(props);
        this.latestArticlesPageSize = 18;
        this.allLatestArticles = this.props.data.allDatoCmsArticle.nodes.slice(1);
        this.state = {
            currentPage: 1,
            latestArticles: this.allLatestArticles.slice(0, this.latestArticlesPageSize)
        }
        setStreamData({
            category: undefined,
            subCategory: this.props.pageContext.title,
            article: undefined,
            author: undefined
        })
        this.seo = {
            title: props.pageContext.title,
            description: `Discover all of our videos, curated by Pension Times Finance Experts.`
        }
    }

    onPageChange = page => {
        this.setArticles(page);
        this.setState({
            currentPage: page,
        });
    };

    setArticles = (page) => {
        const start = (page - 1) * this.latestArticlesPageSize;
        const end = start + this.latestArticlesPageSize;
        this.setState({
            latestArticles: this.allLatestArticles.slice(start, end)
        })
    }

    componentDidMount() {
        addTimeout(videoCategoryPageAds, adDisplayDelay() * delayMultiplicationFactor(isMobile));
    }

    render() {
        return <Layout path={this.props.location.pathname}>
            <Basic seo={this.seo}/>
            <div className="wrapper" data-datocms-noindex>
                <CategoryTitleBordered title={this.props.pageContext.title} className={"mb-2"}/>
                <div className={"lg:flex font-arial"}>
                    <div className={'flex-grow-3'}>
                        {this.props.data.allDatoCmsArticle.nodes.slice(0, 1).map(article =>
                            <VideoArticlePreview data={this.props.data.allDatoCmsArticle.nodes[0]}
                                                 key={article.slug}
                                                 imageClass={"w-full md:w-1/2 lg:mr-2 md:float-left mb-0"}
                                                 headingClass={"pt-6 lg:pt-20 text-lg font-semibold leading-snug"}
                                                 textClass={"lg:p-4 text-sm lg:p-0"}
                                                 noFlag={true}
                                                 imageMeta={{w: 370}}/>
                        )}
                        <div className="ad-container pt-6">
                            <div className="advertisement-text">Advertisement</div>
                            <div id="pt_video_category_incontent1"/>
                        </div>
                        <div className={'pt-6 clear-both'}>
                            <div className="grid grid-cols-12 gap-y-2 lg:gap-6">
                                {this.state.latestArticles.map(article =>
                                    <VideoArticlePreview data={article}
                                                         key={article.slug}
                                                         className={'col-span-12 lg:col-span-4'}
                                                         headingClass={"text-base leading-snug"}
                                                         imageClass={"w-2/5 float-left mr-4 lg:mr-0 lg:w-full lg:float-none"}
                                                         noFlag={true}
                                                         noBody={true}/>
                                )}
                            </div>
                            <div className={"my-6 text-center"}>
                                <Pagination
                                    onChange={this.onPageChange}
                                    current={this.state.currentPage}
                                    total={this.allLatestArticles.length}
                                    pageSize={this.latestArticlesPageSize}
                                />
                            </div>
                        </div>
                    </div>
                    <VideoPopularPosts className="hidden lg:block flex-1 ml-4"/>
                </div>
            </div>
        </Layout>;
    }
}

export const query = graphql`
  query VideosSubCategoryWise($slug: String) {
    allDatoCmsArticle(filter: {category: {elemMatch: {slug: {eq: $slug}}}, website: {elemMatch: {name: {eq: "pt"}}}}, sort: {publishedAt: DESC}) {
        nodes {
            heading
            excerpt
            excerptNode {
              childMarkdownRemark {
                html
              }
            }
            slug
            author {
              name
              slug
            }
            category {
                title
                slug
                treeParent {
                    slug
                }
                position
                website {
                    name
                }
            }
            cover {
              url
                gatsbyImageData(
                    layout: FULL_WIDTH,
                    placeholder: BLURRED,
                    forceBlurhash: false,
                    imgixParams: { auto: "compress", fit: "crop", w: "750", q: 40, ar: "3:2" }
                )
                width
                height
            }
            publishedAt
        }
        totalCount
      }
  }
`
export default VideoCategoryPage
